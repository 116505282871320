import * as React from "react"
import { Helmet } from "react-helmet";
import Layout from '../components/layout';
import { Box, Stack, Heading, Text, Link, Image, Center, AspectRatio, Grid } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import Logo from '../images/logo.svg';
import LogoSmallWithShadow from '../images/logo-small-shadow.svg';
import WaveBlue from '../images/wave-blue.svg';
import BlobDrawing from '../images/beans/drawing.png';
import BlobTicking from '../images/beans/ticking.png';
import BlobDelivery from '../images/beans/delivery.png';
import BlobOnCall from '../images/beans/on-call.png';
import BlobBrand from '../images/brand.png';
import ExampleImage from '../images/ripple-maker/example.png';
import OrangeGradient from '../images/backgrounds/orange-gradient.svg';
import PurpleGradient from '../images/backgrounds/purple-gradient.svg';

const RippleMaker = ({ location }) => {
  return <Layout activePage={location.pathname} hideHeader>
    <Helmet>
      <title>makeripples - Ripple Maker Programme</title>
    </Helmet>
    <Box textAlign="center" p={["2rem", "2rem", "4rem"]} backgroundColor="#FFFFFF">
      <Stack spacing={8} maxWidth={1024} m="auto">
        <Link to="/" as={GatsbyLink} isExternal width="80%" maxWidth="400px" margin="auto">
          <Image src={Logo} />
        </Link>
        <Heading size="2xl">We are building a free version of makeripples!</Heading>
        <Text>An online space to inspire people to connect with for-purpose organisations and take action in their everyday lives. We will ignite the power of individual action by putting the right people in front of the right action to further the mission of the organisations they care about. </Text>
      </Stack>
    </Box>
    <Box p={["2rem", null, null, "2rem", "4rem"]} backgroundColor="#FAF7FA" backgroundImage={["none", null, null, `url(${OrangeGradient}), url(${PurpleGradient}), url(${ExampleImage})`]} backgroundPosition="top left, bottom right, calc(100% - 2rem) 100%" backgroundRepeat="no-repeat" backgroundSize={["auto 40%, auto 40%, 40% auto", null, null, "auto 40%, auto 40%, auto 60%", "auto 40%, auto 40%, auto 70%"]} overflow="hidden">
      <Stack w="100%" maxWidth="1600px" margin="auto" textAlign={["center", null, null, "left"]} direction={["column", null, null, "row"]} justify="space-between" spacing={['4rem', null, null, "2rem", "4rem"]}>
        <Box w="100%" backgroundColor={["inherit", null, null, "#FFF"]} borderRadius="8px" p={["0", null, null, "2rem", "4rem"]} boxShadow={["none", null, null, "-2px 0px 8px rgba(73, 65, 65, 0.08)"]}>
          <Heading size="lg" mt="2rem">What's gonna happen...</Heading>
          <Stack spacing={8} mt={8}>
            <Text>
              We’re asking purpose driven organisations to join us. You know what actions need to be done to get closer to your mission, so tell the world!
            </Text>
            <Text>
              Your actions will appear alongside those of other organisations in our one-stop social hub where anyone can come, connect with organisations from around NZ and take on their calls to action. 
            </Text>
            <Text>
              We’d love for you to join us to get your message out there and watch the ripple effect get you closer to your goals. So fill out the form below and let us know what we can do to make the world a better place.
            </Text>
            <Text>
              We will keep you up to date with our progress and let you know when it’s time to launch! 
            </Text>
          </Stack>
        </Box>
        <Box w="100%">
          
        </Box>
      </Stack>
    </Box>
    <Box backgroundColor="#FFFFFF">
      <Box maxW="1600px" m="auto" p={[0, 0, "2rem", "4rem"]}>
        <Heading size="2xl" py={8} textAlign="center">What can you expect:</Heading>
        <Box overflowX="auto">
          <Box p={10} width="fit-content">
            <Grid templateColumns={["repeat(5, min-content)",null,"repeat(2, minmax(0, 1fr))","repeat(3, minmax(0, 1fr))"]} gap={10}>
              <Card title="Step 1" image={BlobDrawing} text="Fill out a quick form! Don’t worry if it’s not perfect, you will have an opportunity to update this" />
              <Card title="Step 2" image={BlobTicking} text="Sign up to our newsletters to keep up to date with our progress "/>
              <Card title="Step 3" image={BlobDelivery} text="Soft Launch!- this way we can get your feedback and you can edit your content."/>
              <Card title="Step 4" image={BlobOnCall} text="Share it with your community and launch!"/>
              <Card title="Step 5" image={BlobBrand} text="Watch the ripple effect!"/>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box textAlign="center" p={["2rem", "2rem", "4rem"]}>
        <Stack spacing={8} maxW="1024px" m="auto">
          <Heading size="2xl">Ready to go?</Heading>
          <Text>
            We’d love for you to join us to get your message out there and watch the ripple effect get you closer to your goals. So fill out the form below and let us know what we can do to make the world a better place.
          </Text>
          <Text>
            We will keep you up to date with our progress and let you know when it’s time to launch!
          </Text>
        </Stack>
      </Box>
    </Box>
    <Box>
      <Box py="4rem">
        <Center>
          <iframe title="Survey" src="https://docs.google.com/forms/d/e/1FAIpQLScMWKyqaDRqKoxCQYiYkYuYS2DhlTkP7juaMHekECAL75fu2A/viewform?embedded=true" width="640" height="2300" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </Center>
      </Box>
      <Box>
        <Image src={LogoSmallWithShadow} width="100px" margin="auto" mt="4rem" />
        <Box backgroundImage={`url(${WaveBlue})`} backgroundPosition="bottom" w="100%" h="120px" backgroundRepeat="no-repeat" backgroundSize="100% 100%" mt="-55px" />
      </Box>
    </Box>
  </Layout>
}

const Card = ({ title, text, image}) => {
  return <Box rounded="lg" shadow="lg" borderRadius="1rem" overflow="hidden" minWidth={["280px", "280px", "220px"]}>
    <AspectRatio ratio={4/3}>
      <Image src={image} objectFit="scale-down !important" />
    </AspectRatio>
    <Box textAlign="left" p="1rem">
      <Heading size="md" pb={3}>{title}</Heading>
      <Text>{text}</Text>
    </Box>
  </Box>
}

export default RippleMaker;